import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sort',
  standalone: true,
})
export class SortPipe implements PipeTransform {
  transform(array: any[] | undefined, field: string): any[] {
    if (!Array.isArray(array) || !array) {
      return [];
    }
    array.sort((a: any, b: any) => {
      if (!a[field]) {
        return 0;
      }
      if (!b[field]) {
        return -1;
      }

      if (a[field] < b[field]) {
        return -1;
      } else if (a[field] > b[field]) {
        return 1;
      } else {
        return 0;
      }
    });
    return array;
  }
}
