import {
  Component,
  ChangeDetectionStrategy,
  forwardRef,
  Input,
  Inject,
  OnInit,
  Output,
  EventEmitter,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { APP_CONFIG } from '@core/interfaces/configs';
import { APPCONFIG } from '@core/tokens/configs';
import { ILinkedFI } from '@store/common/user.interface';
import { MenuAction, MenuComponent } from '../menu/menu.component';
import { ACTION } from '../payment-method-list/payment-method-list.component';
import { TranslateModule } from '@ngx-translate/core';

import { PbbSvgIconComponent } from '../pbb-svg-icon/pbb-svg-icon.component';
import { MatChipsModule } from '@angular/material/chips';
import { AddressSuggestionComponent } from '../address-suggestion/address-suggestion.component';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { NgIf, NgFor } from '@angular/common';
import { MatRadioModule } from '@angular/material/radio';
import { IdFormatPipe } from '@core/pipes/id-format.pipe';
import { JsonStringifyPipe } from '@core/pipes/json-stringify.pipe';
import { SortPipe } from '@core/pipes/sort.pipe';

export interface SelectedAccount {
  selectedAccountId: string;
  selectedFiId: string;
  selectedFiUserId: string;
}

@Component({
  selector: 'pbb-user-account-selector',
  templateUrl: './user-account-selector.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UserAccountSelectorComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [
    MatRadioModule,
    ReactiveFormsModule,
    FormsModule,
    NgIf,
    NgFor,
    MatExpansionModule,
    MatIconModule,
    AddressSuggestionComponent,
    MatChipsModule,
    PbbSvgIconComponent,
    MenuComponent,
    JsonStringifyPipe,
    IdFormatPipe,
    SortPipe,
    TranslateModule,
  ],
})
export class UserAccountSelectorComponent implements ControlValueAccessor, OnInit {
  selectedAccount!: string;
  Action = ACTION;
  @Input() defaultAccountId!: string;
  @Input() defaultSavingAccountId!: string;
  @Input() linkedFis: Array<ILinkedFI> = [];
  @Input() showSetDefault = false;
  @Input() showAsAccordion = false;
  @Output() setDefault = new EventEmitter<any>();
  @Input() showFi = true;
  // @Input() disableAccounts = false;

  selectedFiIndex!: number;
  constructor(@Inject(APPCONFIG) private readonly appConfig: APP_CONFIG) {
    this.onChange = () => {};
  }

  onChange: any = () => {};
  onTouch: any = () => {};
  ngOnInit() {
    this.showAsAccordion = this.linkedFis.length > this.appConfig.linkedFiLimitForAccordionView && this.showAsAccordion;
  }
  get value() {
    return this.selectedAccount;
  }

  set value(val: string) {
    this.selectedAccount = val;
    this.onChange(val);
    this.onTouch(val);
  }
  //From ControlValueAccessor interface
  writeValue(value: string) {
    this.selectedAccount = value;
    if (this.selectedAccount) {
      const { selectedAccountId } = JSON.parse(this.selectedAccount);
      this.selectedFiIndex = this.linkedFis.findIndex((fi) => {
        return fi.accounts?.some((acc) => acc.fi_account_ref === selectedAccountId);
      });
    }
  }
  //From ControlValueAccessor interface
  registerOnChange(fn: any) {
    this.onChange = fn;
  }
  //From ControlValueAccessor interface
  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  setPaymentMethod(menuAction: MenuAction, linkedFiRef: string, paymentMethodRef: string) {
    this.setDefault.emit({ linkedFiRef, paymentMethodRef });
  }
}
